<template>
  <v-dialog v-model="dialogMaterial" width="600">
    <template v-slot:activator="{ on }" v-if="tipoModal">
      <v-btn
        v-on="on"
        class="verdeBoton"
        :disabled="existeCuenta"
        @click="limpiarMaterial()"
      >Agregar Material</v-btn>
    </template>
    <v-card>
      <v-card-text>
        <!-- Este es el contenido del dialogo -->
        <base-material-card color="drawerColorSecondary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Material</div>
          </template>
          <v-combobox
            v-model="materialNuevo"
            :items="this.materiales"
            item-text="nombre"
            item-value="idMaterial"
            label="Material"
            class="margenMaterial"
            @change="nuevoMaterial"
            return-object
            auto-select-first
          />
          <v-text-field
            v-model="descripcionMaterial"
            :disabled="isDescripcion"
            label="Descripción"
            class="margenMaterial"
          />
          <v-row justify="center">
            <v-col cols="6">
              <v-select
                v-model="potenciadoNuevo"
                v-bind:disabled="isUnidad"
                :items="potenciador"
                label="Potenciador"
                class="margenMaterial"
                @input="validarBotonAgregar"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="cantidadNuevo"
                @input="setCantidadMaterial"
                :items="cantidad"
                label="Cantidad"
                class="margenMaterial"
                open-on-clear
              />
            </v-col>
          </v-row>
          <v-btn color="verdeBoton" @click="dialogMaterial = false">Cancelar</v-btn>
          <v-btn color="verdeBoton" :disabled="colorBotonAgregar" @click="agregarPrestamo()">Aceptar</v-btn>
        </base-material-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalMaterial",
  props: {
    existeCuenta: {
      type: Boolean,
      default: true,
    },
    tipoModal: Boolean,
    cuenta: Object,
  },
  data() {
    return {
      cantidad: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      potenciador: [
        "N/A",
        "Pico",
        "Nano",
        "Micro",
        "Mili",
        "Kilo",
        "Mega",
        "Giga",
        "Tera",
      ],
      colorBotonAgregar: true,
      materiales: [],
      materialNuevo: [],
      potenciadoNuevo: "",
      cantidadNuevo: "",
      descripcionMaterial: "",
      isDescripcion: true,
      isUnidad: true,
      dialogMaterial: false,
      cantidadMaterial: 0,
    };
  },
  mounted() {
    /**
     * Aqui hacemos la peticion de los materiales para ser utilizados
     * Se almacenan todos los materiales en un array.
     */
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/materiales", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((materiales) => {
        this.materiales = materiales;
      })
      .catch((err) => {
        return err;
      });
  },
  watch: {
    materialNuevo() {
      this.validarBotonAgregar();
    },
    descripcionMaterial() {
      this.validarBotonAgregar();
    },
    potenciador() {
      this.validarBotonAgregar();
    },
    /* cantidadMaterial() {
      this.validarBotonAgregar();
    }, */
  },
  methods: {
    /*Metodo para comprobar que la informacion para agregar prestamo es correcta.
    Una vez validado el boton se desbloquea para realizar el prestamo
    */
    validarBotonAgregar() {
      if (
        this.materialNuevo == null ||
        this.materialNuevo == "" ||
        this.materialNuevo.nombre == undefined
      ) {
        this.isDescripcion = true;
        this.isUnidad = true;
        this.colorBotonAgregar = true;
        return;
      }
      this.colorBotonAgregar = true; //true
      //Primero verificamos si ocupa descripcion
      if (this.materialNuevo.descripcion == true) {
        //verificamos si ocupa potenciador
        if (this.materialNuevo.unidadM != "PIEZA") {
          if (
            this.descripcionMaterial != "" &&
            this.materialNuevo.length != 0 &&
            this.cantidadNuevo >= 1 &&
            this.potenciadoNuevo != ""
          ) {
            this.colorBotonAgregar = false;
          }
        } else {
          if (
            this.descripcionMaterial != "" &&
            this.materialNuevo.length != 0 &&
            this.cantidadNuevo >= 1
          ) {
            this.colorBotonAgregar = false;
          }
        }
      } else if (this.materialNuevo.length != 0 && this.cantidadNuevo >= 1) {
        this.colorBotonAgregar = false;
      }
    },
    /**
     * Se encarga de hacer un POST de un nuevo prestamo para mostrarlo en la tabla.
     */
    agregarPrestamo() {
      //Si no se selecciona un material se cierra la funcion
      if (this.materialNuevo.length == 0) {
        return;
      }

      //Si el material necesita descripcion y no ha ingresado nada, se cierra la funcion
      if (
        this.materialNuevo.descripcion == true &&
        this.descripcionMaterial == ""
      ) {
        return;
      }

      //Si el material necesita un potenciador y no se ha ingresado nada, se cierra
      if (
        this.materialNuevo.unidadM != "PIEZA" &&
        this.materialNuevo.unidadM != "" &&
        this.potenciadoNuevo == ""
      ) {
        return;
      }

      if (this.cantidadMaterial > 0 || this.materialNuevo != []) {
        for (let index = 0; index < this.cantidadMaterial; index++) {
          if (this.isDescripcion == true) {
            this.descripcionMaterial = "N/A";
          }
          if (this.isUnidad == true) {
            this.potenciadoNuevo = "N/A";
          }
          fetch(
            process.env.VUE_APP_URL_BACKEND + "/administrador/prestamos",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.state.cuenta.token,
              },
              body: JSON.stringify({
                idCuenta: this.cuenta.idCuenta,
                descripcion: this.descripcionMaterial,
                potenciador: this.potenciadoNuevo,
                idMaterial: this.materialNuevo.idMaterial,
                material: this.materialNuevo.nombre,
                unidadM: this.materialNuevo.unidadM,
              }),
            }
          )
            .then((response) => {
              return response.json();
            })
            .then((prestamo) => {
              if (!prestamo.errors) {
                let newDate = this.$cambiarFormatoDate(prestamo.fechaPedido);
                prestamo.fechaPedido = newDate;
                this.$emit("addPrestamo", prestamo);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        this.dialogMaterial = false;
      }
    },

    /**
     * Se encarga de buscar el nuevo material como tambien habilitar si tiene descripcion o potenciador.
     */
    nuevoMaterial() {
      if (
        this.materialNuevo == null ||
        this.materialNuevo == "" ||
        this.materialNuevo.nombre == undefined
      ) {
        this.isDescripcion = false;
        this.isUnidad = false;
        return;
      }
      if (this.materialNuevo.descripcion == true) {
        this.isDescripcion = false;
      } else {
        this.isDescripcion = true;
      }
      if (
        this.materialNuevo.unidadM === "PIEZA" ||
        this.materialNuevo.unidadM === ""
      ) {
        this.isUnidad = true;
      } else {
        this.isUnidad = false;
      }
    },

    /**
     * Se encarga de cambiar la cantidad de materiales
     * @value es el nuevo valor para la cantidad de elementos del nuevo prestamo
     */
    setCantidadMaterial(value) {
      this.cantidadMaterial = value;
      this.validarBotonAgregar();
    },

    /**
     * Metodo para limpiar las variables principales del material
     */
    limpiarMaterial() {
      this.materialNuevo = [];
      this.descripcionMaterial = "";
      this.potenciadoNuevo = "";
      this.isDescripcion = true;
      this.isUnidad = true;
    },
  },
};
</script>

<style>
.margenMaterial {
  margin-left: 10px;
  margin-right: 10px;
}

.ajusteIzquierda {
  margin-left: 10px;
}
.ajusteDerecha {
  margin-right: 10px;
}
</style>