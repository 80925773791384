<template>
  <v-dialog v-model="visible" width="600">
    <v-card>
      <v-card-text>
        <!-- Este es el contenido del dialogo -->
        <base-material-card color="indigo darken-4">
          <template v-slot:heading>
            <div class="display-2 font-weight-medium">Material</div>
          </template>
          <v-combobox
            v-model="materialNuevo"
            :items="this.materiales"
            item-text="nombre"
            item-value="idMaterial"
            label="Material"
            class="margenMaterial"
            @change="nuevoMaterial()"
            return-object
            auto-select-first
          />

          <v-row justify="center">
            <v-col cols="6">
              <v-text-field
                v-model="descripcionMaterial"
                :disabled="isDescripcion"
                label="Descripción"
                class="margenMaterial"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="potenciadoNuevo"
                v-bind:disabled="isUnidad"
                :items="this.potenciador"
                label="Potenciador"
                class="margenMaterial"
              />
            </v-col>
          </v-row>
          <v-btn color="primary" @click="cancelar()">Cancelar</v-btn>
          <v-btn color="primary" @click="editarPrestamo()">Aceptar</v-btn>
        </base-material-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalEditarMaterial",
  props: {
    dialogMaterial: {
      type: Boolean,
      default: false,
    },
    cuenta: Object,
    material: Object,
  },
  data() {
    return {
      potenciador: [
        "N/A",
        "Pico",
        "Nano",
        "Micro",
        "Mili",
        "Kilo",
        "Mega",
        "Giga",
        "Tera",
      ],
      materiales: [],
      materialNuevo: [],
      potenciadoNuevo: "",
      cantidadNuevo: "",
      descripcionMaterial: "",
      isDescripcion: true,
      isUnidad: true,
      visible: false,
      cantidadMaterial: 1,
    };
  },
  mounted() {
    /**
     * Aqui hacemos la peticion de los materiales para ser utilizados
     * Se almacenan todos los materiales en un array.
     */
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/materiales", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((materiales) => {
        this.materiales = materiales;
      })
      .catch((err) => {
        return err;
      });
  },
  watch: {
    material() {
      this.materialNuevo = this.materiales.find(
        (itemD) => itemD.nombre === this.material.material
      );
      this.potenciadoNuevo = this.potenciador.find(
        (itemD) => itemD === this.material.potenciador
      );
      this.descripcionMaterial = this.material.descripcion;

      this.nuevoMaterial();
    },
    dialogMaterial() {
      if (this.dialogMaterial && !this.visible) this.show();
      else if (!this.dialogMaterial && this.visible) this.hide();
    },
  },
  methods: {
    /**
     * Se encarga de buscar el nuevo matrial como tambien habilitar si tiene descripcion o potenciador.
     * @index es el nuevo material que se quiere agregar
     */
    nuevoMaterial() {
      if (this.materialNuevo.descripcion == true) {
        this.isDescripcion = false;
      } else {
        this.descripcionMaterial = "";
        this.isDescripcion = true;
      }
      if (
        this.materialNuevo.unidadM === "PIEZA" ||
        this.materialNuevo.unidadM === ""
      ) {
        this.isUnidad = true;
        this.potenciadoNuevo = "";
      } else {
        this.isUnidad = false;
      }
    },
    /**
     * editarPrestamo
     */
    editarPrestamo() {
      fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/prestamos", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.cuenta.token,
        },
        body: JSON.stringify({
          idPrestamo: this.material.idPrestamo,
          idMaterial: this.materialNuevo.idMaterial,
          descripcion: this.descripcionMaterial,
          potenciador: this.potenciadoNuevo,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((prestamo) => {
          let newDate = this.$cambiarFormatoDate(prestamo.fechaPedido);
          prestamo.fechaPedido = newDate;
          prestamo.value = false;
          this.cancelar();
          this.$emit('editarPrestamos', prestamo);
        });
    },
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },
    cancelar() {
      this.hide();
      this.$emit("ocultar");
    },
  },
};
</script>

<style>
</style>