<template>
  <base-material-card color="drawerColorSecondary">
    <template v-slot:heading>
      <div class="display-2 font-weight-light">Buscar Cuenta</div>
    </template>
    <template v-slot:actions>
      <v-text-field
        ref="matr"
        label="Matrícula del Alumno"
        type="text"
        hide-details
        @keypress="onlyNumber"
        counter="15"
        v-model="matriculaABuscar"
        @keyup.enter="obtenerCuenta(matriculaABuscar)"
        
      />
      <v-btn
        ref="botonBuscarMatricula"
        fab
        color="verdeBoton"
        style="margin: 0px 0px 0px 25px;"
        @click="obtenerCuenta(matriculaABuscar)"
      >
        <v-icon dark>mdi-account-search</v-icon>
      </v-btn>
    </template>
  </base-material-card>
</template>

<script>
export default {
  name: "BuscarPorMatricula",
  data() {
    return {
      matriculaABuscar: this.idUsuario,
    };
  },
  props: {
    tiposDePrestamos: String,
    idUsuario: String
  },
  watch: {
    idUsuario: function(newVal, oldVal) {
      this.matriculaABuscar = newVal;
    }
  },
  methods: {
    /**
     * Se encargara de buscar por matricula todos sus prestamos con estado SOLICITADOS
     * y tambien buscar su tutor y carrera para ser mostrados en pantalla.
     * @matricula es la matricula a buscar
     */
    obtenerCuenta(matricula) {
      if (matricula != "") {
        this.$emit("cambiarVariables");
        fetch(
          process.env.VUE_APP_URL_BACKEND +
            "/administrador/cuentas/" +
            matricula,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.cuenta.token,
            },
          }
        )
          .then((response) => {
            if (response.status === 404) {
              alert("No se encontro la cuenta.");
              return response.json();
            }
            return response.json();
          })
          .then((cuenta) => {

            if (cuenta !== null) {
              if (cuenta.prestamos.length > 0) {
                //Si encontramos la matricula y tiene prestamos entonces continuo haciendo peticiones para conseguir sus prestamos
                //prestados y el nombre de su tutor y la carrera.
                fetch(
                  process.env.VUE_APP_URL_BACKEND +
                    "/administrador/prestamos/" +
                    this.tiposDePrestamos +
                    "/" +
                    cuenta.idCuenta,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + this.$store.state.cuenta.token,
                    },
                  }
                )
                  .then((response) => {
                    return response.json();
                  })
                  .then((prestamos) => {
                    prestamos.forEach((prestamo) => {
                     /*  let newDate = this.$cambiarFormatoDate(
                        prestamo.fechaPedido
                      );
                      prestamo.fechaPedido = newDate; */
                      prestamo.value = false;
                    });
                    this.$emit("prestamosCuentas", prestamos, cuenta);
                  });
              } else {
                this.$emit("prestamosCuentas", cuenta.prestamos, cuenta);
              }
            } else {
              this.$alert(
                "No existe una cuenta registrada con esa matrícula en el sistema.",
                "Error al encontrar la cuenta.",
                "warning"
              ).then(() => this.$emit("cambiarEstadoData"));
            }
          });
      }
    },
    /**
     * Se agrega al campo de matricula para validad que solo se ingresen numeros.
     * @$event es el evento que se escuchara
     */
    onlyNumber($event) {
      if (this.$onlyNumber($event.keyCode, $event.which)) {
        $event.preventDefault();
      }
    },
  },
};
</script>