<template>
  <base-material-card color="drawerColorSecondary" >
    <template v-slot:heading>
      <div class="display-2 font-weight-light">Datos de la Cuenta</div>
    </template>
    <div v-if="!isMobile">
      <v-text-field v-model="this.cuenta.nombre" label="Nombre(s)" disabled></v-text-field>
      <v-text-field v-model="this.cuenta.apellidos" label="Apellidos" disabled></v-text-field>
      <v-text-field v-model="this.cuenta.correo" label="Correo" disabled></v-text-field>
      <div v-if="cuenta.tutor != undefined">
        <v-text-field v-model="cuenta.tutor" label="Tutor" disabled></v-text-field>
      </div>
      <div v-else></div>
      <v-text-field v-model="this.cuenta.carrera" label="Carrera" disabled></v-text-field>
      <v-text-field v-model="this.cuenta.tipo" label="Tipo de Usuario" disabled></v-text-field>
    </div>
    <div v-else>
      <v-text-field v-model="this.cuenta.nombre" label="Nombre(s)" disabled></v-text-field>
      <v-text-field v-model="this.cuenta.apellidos" label="Apellidos" disabled></v-text-field>
    </div>
    <v-btn color="verdeBoton" :disabled="existeCuenta" @click="limpiarDatos">Limpiar Datos</v-btn>
  </base-material-card>
</template>

<script>
export default {
  name: "MostrarDatosCuenta",
  props: {
    cuenta: Object,
    tutor: Object,
    carrera: Object,
    existeCuenta: Boolean,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    limpiarDatos() {
      this.$emit("limpiarDatos");
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },
};
</script>
